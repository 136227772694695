@import "framework";

// Vars

$body-size: 14px;
$ref-width: 1366px;
$fg-color: #000000;
$bg-color: #fffefb;
$accent-color: #ef4c26;
$body-font-family: "courier new", courier, monospace;

$mobile: (body-size: ($body-size * 1.5), ref-width: 600px, breakpoint: 600px);

// $gutter: 50px;
$gutter: 71px;
$gutter-mobile: 35px;
// $logo-offset-top: $gutter;
$logo-offset-top: 87px;
$logo-offset-top-mobile: $gutter-mobile * 0.7;
// $logo-width: 295px;
$logo-width: 326px;
$logo-width-mobile: 200px;
// $menu-offset-top: 190px;
$menu-offset-top: 292px;

// $header-width: 230px;
$header-width: 213px;
$header-color: #f3eee8;
$header-height-mobile: 40px + $logo-offset-top-mobile * 2;

// Helpers

@mixin maxwidth() {
  @media screen and (min-width: $ref-width) {
    @content;
  }
}
// Global

body {
  background-color: $bg-color;
  color: $fg-color;
  font-family: $body-font-family;
  @include set-body-size;
  line-height: 1.4;
  @include mobile {
    @include set-body-size;
  }
  @include maxwidth {
    font-size: $body-size;
  }
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

#app > header {
  z-index: 2;
  background-color: $header-color;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: vw($header-width);
  @include mobile {
    width: 100%;
    height: vw($header-height-mobile);
  }
  @include maxwidth {
    width: $header-width;
  }
  .logo {
    position: absolute;
    left: vw($gutter);
    top: vw($logo-offset-top);
    width: vw($logo-width);
    > img.mobile { display: none; }
    @include mobile {
      left: vw($gutter-mobile);
      top: vw($logo-offset-top-mobile);
      width: vw($logo-width-mobile);
      > img.mobile { display: block; }
      > img.desktop { display: none; }
    }
    @include maxwidth {
      left: $gutter;
      top: $logo-offset-top;
      width: $logo-width;
    }
  }
  nav {
    height: 100%; height: 100vh;
    padding: vw($menu-offset-top) 0em vw($gutter) vw($gutter);
    overflow-x: visible;
    // overflow-y: scroll;
    @include mobile {
      position: absolute;
      left: 0;
      top: vw($header-height-mobile);
      padding: vw($gutter-mobile);
      width: 100%;
      height: calc(100vh - #{vw($header-height-mobile)});
      background-color: $header-color;
      @include transition(0.2s ease-in-out);
      @include transform(translateY(100%));
      &.active {
        @include transform(translateY(0%));
      }
    }
    @include maxwidth {
      padding: $menu-offset-top 0em $gutter $gutter;
    }
    li {
      margin-bottom: 1em;
    }
    > ol {
      padding-bottom: vw($gutter);
      @include maxwidth {
        padding-bottom: $gutter;
      }
    }
    li > .subnav {
      cursor: pointer;
    }
    li > ol {
      padding-top: 1em;
      padding-left: 1em;
      max-height: 100vh;
      overflow: hidden;
      @include transition(0.2s ease-in-out);
      &:not(.expanded) {
        max-height: 0vh;
        padding-top: 0;
      }
    }
    a {
      font-weight: normal;
      &:hover {
        text-decoration: none;
        color: $accent-color;
      }
      &.router-link-exact-active {
        text-decoration: underline;
      }
    }
  }
}

#app > main {
  z-index: 1;
  padding-left: vw($header-width);
  @include mobile {
    padding-left: 0;
    padding-top: vw($header-height-mobile);
  }
  @include maxwidth {
    padding-left: $header-width;
  }
}

// Forms

.field {
  margin: 1em 0;
  label + input {
    margin-left: 1em;
    min-width: 20em;
    @include mobile {
      margin-left: 0;
    }
  }
}

input[type=text],
input[type=email],
select {
  @include vendor(appearance, none);
  min-width: 30em;
  border: 0;
  border-bottom: 1px dotted $fg-color;
  border-radius: 0;
  font-family: $body-font-family;
  font-size: 1em;
  padding: 0 0 0.3em 0;
  outline: 0;
  color: $fg-color;
  background-color: transparent;
  &.short {
    min-width: 10em;
  }
  @include mobile {
    min-width: 100%;
  }
  @include placeholder {
    color: $fg-color;
  }
}

textarea {
  min-width: 40em;
  min-height: 10em;
  border: 1px dotted $fg-color;
  border-radius: 0;
  font-family: $body-font-family;
  font-size: 1em;
  padding: 0.3em;
  outline: 0;
  color: $fg-color;
  background-color: transparent;
  @include mobile {
    min-width: 100%;
  }
  @include placeholder {
    color: $fg-color;
  }
}

input[type=checkbox],
input[type=radio] {
  + span {
    margin-left: 0.5em;
  }
}

.buttons {
  margin: 2em 0;
  button + button {
    margin-left: 2em;
  }
  button:last-child {
    color: $accent-color;
    &:hover {
      border-bottom: 1px dotted $accent-color;
    }
  }
}

button {
  @include vendor(appearance, none);
  border: 0;
  padding: 0 0 0.3em 0;
  margin: 0;
  outline: 0;
  color: $fg-color;
  background-color: transparent;
  font-size: 1em;
  font-family: $body-font-family;
  cursor: pointer;
  @include transition(0.2s);
  &:hover {
    border-bottom: 1px dotted $fg-color;
  }
}

// Home

.home-page {
  padding: vw($gutter / 8);
  background-color: #000000;
  overflow: hidden;
  @include mobile {
    padding: vw($gutter-mobile / 4);
  }
  @include maxwidth {
    padding: ($gutter / 8);
  }
}

.animated-loops {
  // max-width: 1049px;
  max-width: 1500px;
  .asset, .grid-sizer {
    float: left;
    padding: vw($gutter / 8);
    width: (100% / 4);
    @include mobile {
      padding: vw($gutter-mobile / 4);
      width: 50%;
    }
    @include maxwidth {
      padding: ($gutter / 8);
    }
  }
}

// Demo

.video-wrap {
  @include fixed-proportions-wrap(16, 9);
}

.demo-reels {
  width: vw(800px);
  padding-top: vw($menu-offset-top);
  @include mobile {
    width: 100%;
    padding-top: vw($gutter-mobile);
  }
  @include maxwidth {
    width: 800px;
    padding-top: $menu-offset-top;
  }
  .demo-reel {
    margin-bottom: vw($gutter * 2);
    @include mobile {
      margin-bottom: vw($gutter-mobile * 2);
    }
    @include maxwidth {
      margin-bottom: ($gutter * 2);
    }
    .description {
      padding: 0 vw($gutter);
      @include mobile {
        padding: 0 vw($gutter-mobile);
      }
      @include maxwidth {
        padding: 0 $gutter;
      }
      h1 {
        font-size: inherit;
        margin: 0;
      }
    }
  }
}

// Work

.projects {
  > li {
    position: relative;
    display: block;
    float: left;
    width: (100% / 4);
    @each $i in 5 6 7 8 9 10 11 12 13 14 15 {
      @media screen and (min-width: ($header-width + ($i - 1) * 250px)) {
        width: (100% / $i);
      }
    }
    @include mobile {
      width: 50%;
    }
    a {
      display: block;
      width: 100%;
      @include fixed-proportions-wrap(100, 72.2);
    }
    &.portrait a {
      @include fixed-proportions-wrap(100, 72.2 * 2);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      @include opacity(1);
      @include transition(0.5s);
      &:hover {
        @include opacity(0);
      }
    }
  }
}

.modal-route {
  z-index: 3;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(#000000, 0.95);
  overflow: scroll;
  .modal-content {
    position: absolute;
    width: 60%;
    max-height: 100vh;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
    color: #ffffff;
    @include mobile {
      width: 100%;
      max-height: 60vh;
    }
    .project-details {
      padding: vw($gutter);
      @include mobile {
        padding: vw($gutter-mobile);
      }
    }
    .modal-video {
      background-color: #ffffff;
      border: vw(10px) solid #ffffff;
    }
    .video-wrap + .video-wrap {
      margin-top: 1em;
    }
  }
}

// Contact

.contact-page {
  width: vw(800px);
  padding: vw($menu-offset-top) vw($gutter) vw($gutter) vw($gutter);
  @include mobile {
    width: 100%;
    padding: vw($gutter-mobile);
  }
  @include maxwidth {
    width: 800px;
    padding-top: $menu-offset-top $gutter $gutter $gutter;
  }
}

// Quote request

.quote-page {
  width: vw(800px);
  padding: vw($menu-offset-top) vw($gutter) vw($gutter) vw($gutter);
  @include mobile {
    width: 100%;
    padding: vw($gutter-mobile);
  }
  @include maxwidth {
    width: 800px;
    padding: $menu-offset-top $gutter $gutter $gutter;
  }
  h1 {
    margin-top: 0;
    line-height: 1;
  }
  h1, h2 {
    font-size: 1em;
  }
}

// Transitions

[v-cloak] { display: none; }

.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}
.fade-enter-to, .fade-leave {
  opacity: 1;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fadein-enter-active {
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}
.fadein-enter-to {
  opacity: 1;
}
.fadein-enter {
  opacity: 0;
}

.fadeout-leave-active {
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}
.fadeout-leave {
  opacity: 1;
}
.fadeout-leave-to {
  opacity: 0;
}

// slide up/down

.fadeslideprev-enter-active,
.fadeslideprev-leave-active,
.fadeslidenext-enter-active,
.fadeslidenext-leave-active {
  @include transition(0.5s);
}

.fadeslidenext-enter,
.fadeslideprev-leave-to {
  @include opacity(0);
  @include transform(translateX(5em));
}

.fadeslidenext-enter-to,
.fadeslidenext-leave,
.fadeslideprev-enter-to,
.fadeslideprev-leave {
  @include opacity(1);
  @include transform(translateX(0));
}

.fadeslidenext-leave-to,
.fadeslideprev-enter {
  @include opacity(0);
  @include transform(translateX(-5em));
}


